<template>
  <a-row type="flex" justify="center">
    <a-col v-if="!short" :span="10">
      <a-descriptions title="Información de Origen"
                          bordered size="small" :column="1">
        <a-descriptions-item label="Planta">
          <strong v-if="mixture.formulations && mixture.formulations.work_centers && mixture.formulations.work_centers.name">
            {{mixture.formulations.work_centers.name}}
          </strong>
        </a-descriptions-item>
        <a-descriptions-item label="Orden de Producción">
          <strong v-if="mixture.formulations && mixture.formulations.code">{{mixture.formulations.code}}</strong>
        </a-descriptions-item>
      </a-descriptions>
    </a-col>
    <a-col :span="short ? 24:13" :offset="short ? 1:0">
      <a-descriptions title="Datos Generales"
                          bordered size="small" :column="{ xs: 1, sm: 1, md: 2}">
        <a-descriptions-item label="Corriente">
          <strong v-if="mixture.currents && mixture.currents.name">{{mixture.currents.name}}</strong>
        </a-descriptions-item>
        <a-descriptions-item label="Mezcla">
          <strong>{{mixture.code}} - {{mixture.name}}</strong>
        </a-descriptions-item>
        <a-descriptions-item label="Total de material formulado">
          <strong>{{mixture.total_formule_material}}
            -
            <span  v-if="mixture.ums && mixture.ums.name">
              {{mixture.ums.name}}
            </span>
          </strong>
        </a-descriptions-item>
        <a-descriptions-item label="Área de almacenaje">
          <strong v-if="mixture.finished_product_areas && mixture.finished_product_areas.name">{{mixture.finished_product_areas.name}}</strong>
        </a-descriptions-item>
        <a-descriptions-item label="Fila | Cajón">
          <strong>{{mixture.row}} | {{mixture.drawer}}</strong>
        </a-descriptions-item>
        <a-descriptions-item label="Fecha de Ingreso">
          <strong v-if="mixture.formulations && mixture.formulations.created_at">
            {{moment(mixture.formulations.created_at).format('DD/MM/YYYY')}}
          </strong>
        </a-descriptions-item>
      </a-descriptions>
    </a-col>
  </a-row>
</template>

<script>
export default {
  name: 'mixtureTableInfo',
  props: {
    mixture: {
      type: Object,
      required: true,
    },
    short: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  // mounted() {
  //   console.log(this.mixture)
  // },
}
</script>
