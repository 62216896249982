<template>
  <div>
    <!-- Filtros -->
    <a-row align="middle">
      <a-col :sm="{ span: 11 }" :md="{ span: 8 }">
        <!-- SELECT Planta -->
        <customDropdownSelect v-model="workCenterIdFilter"
                               label="Planta" resource="workCenters"/>
      </a-col>
      <a-col :sm="{ span: 11 }" :md="{ span: 7 }">
        <!-- SELECT Planta de Destino -->
        <customDropdownSelect v-model="destinyWorkCenterIdFilter"
                               label="Planta de Destino" resource="workCenters"/>
      </a-col>
      <a-col :sm="{ span: 11 }" :md="{ span: 7 }">
        <!-- SELECT Tipo de Formulacion -->
        <customDropdownSelect v-model="formulationTypeIdFilter"
                               label="Tipo de Formulación" resource="formulationTypes"/>
      </a-col>
      <a-col :sm="{ span: 2 }" :md="{ span: 2 }" :lg="{ span: 2 }" class="mt-5">
        <b-button size="sm" variant="default" @click="FetchTable()">
           <b-icon icon="search"/>
        </b-button>
      </a-col>
      <a-col :sm="{ span: 24 }" :md="{ span: 24 }">
       <!--TEXT - No.Manifiesto de Salida-->
       <a-form-item>
       <span slot="label">
         <strong>Buscar por: </strong>
         <span>No.Lote - No.Manifiesto de Salida - Aplica/No Aplica</span>
       </span>
          <a-input v-model="manifestFilterText" size="large"/>
       </a-form-item>
       <!--TEXT -No.Manifiesto de Salida -->
      </a-col>
      <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
        <!--DATEPICKER - Fecha final-->
        <a-form-model-item  label="Fecha final">
          <a-date-picker  v-model="startDate" placeholder="Fecha final" style="width: 100%"
                          format="DD/MM/YYYY" size="large" valueFormat="YYYY-DD-MM"/>
        </a-form-model-item>
        <!--DATEPICKER -Fecha final -->
      </a-col>
      <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
        <!--DATEPICKER - Fecha final-->
        <a-form-model-item  label="Fecha final">
          <a-date-picker  v-model="finalDate" placeholder="Fecha final" style="width: 100%"
                          format="DD/MM/YYYY" size="large" valueFormat="YYYY-DD-MM"/>
        </a-form-model-item>
        <!--DATEPICKER -Fecha final -->
      </a-col>
    </a-row>
    <!-- Filtros -->
    <hr class="bg-primary">
    <!-- Tabla -->
     <a-row>
      <a-table bordered :columns="tableObj.columns"
          :data-source="tableObj.data"
          :pagination="tableObj.pagination"
          :loading="tableObj.loading"
          :row-key="record => record.id"
          @change="FetchTable">
          <template slot="waste" slot-scope="row">
            <strong>Planta: </strong>{{row.work_centers.name}} <br>
            <strong>No.Lote: </strong>{{row.manifest_batch_code}} <br>
            <strong>No.Manifiesto de salida y Disposición Fiscal: </strong>{{row.exit_manifest_code}} <br>
            <strong>Tipo de Formulación: </strong>{{row.formulation_types.name}} <br>
            <strong>Fecha de Reciclaje: </strong>{{moment(row.recycle_dated_at).format('DD/MM/YYYY')}} <br>
            <strong>Cantidad Total: </strong>{{row.quantity}} <br>
            <strong>UM: </strong>{{row.ums.name}}
          </template>
          <template slot="transport" slot-scope="row">
            <strong>Empresa Transportista: </strong>{{row.carrier_company_name}} <br>
            <strong>Nombre del Chofer: </strong>{{row.carrier_company_driver}} <br>
            <strong>Tipo de Vehículo: </strong>{{row.vehicle_type}} <br>
            <strong>Placas de Tractocamio: </strong>{{row.vehicle_tag}} <br>
            <strong>Placas de la Caja: </strong>{{row.vehicle_tag_coupled}} <br>
            <strong>No.Autorización SEMARNAT: </strong>{{row.semarnat_2}} <br>
            <strong>No.Secretaría Relaciones y Transportes: </strong>{{row.external_relations_secretary}}
          </template>
          <template slot="final" slot-scope="row">
            <strong>Tipo de Salida: </strong>{{row.exit_type}} <br>
            <strong>No.Programación: </strong>{{row.programming_code}} <br>
            <strong>Razón Social de la empresa de destino final: </strong>{{row.business_name}} <br>
            <template v-if="row.status_applied == 'Aplica'">
              <strong>No.Autorización Destino Final: </strong>{{row.authorization_final_destiny}} <br>
            </template>
            <template v-else>
              <strong>Planta Destino: </strong>{{row.authorization_final_destiny}} <br>
              <strong>Punto de Alimentacción: </strong>{{row.feeding_point}} <br>
            </template>
            <strong>Manejo autorizado que dará la empresa al residuo: </strong>{{row.authorization_final_code}} <br>
            <strong>Responsable: </strong>{{row.responsible}}
          </template>
          <template slot="actions" slot-scope="row">
            <b-button pill variant="default" :to="{name: 'finished-product-manifests', params: {id: row.id}}">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Ver Detalles">
                <b-icon icon="arrow-right"/>
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Ver Detalles">
                <b-icon icon="arrow-right"/>
              </div>
            </b-button>
          </template>
      </a-table>
    </a-row>
    <!-- Tabla -->
  </div>
</template>

<script>
import { apiV2Mixin } from '@/mixins/ApiV2Mixin'
export default {
  name: 'plantShipmentsTab',
  mixins: [apiV2Mixin],
  data() {
    return {
      // Filtros
      workCenterIdFilter: undefined,
      destinyWorkCenterIdFilter: undefined,
      formulationTypeIdFilter: undefined,
      loteFilterText: '',
      manifestFilterText: '',
      startDate: '',
      finalDate: '',
      // Filtros
      // Table
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          { title: 'Datos de residuo', dataIndex: '', class: 'text-center', scopedSlots: { customRender: 'waste' } },
          { title: 'Datos del Transporte', dataIndex: '', class: 'small text-center', scopedSlots: { customRender: 'transport' } },
          { title: 'Destinatario Final', dataIndex: '', class: 'small text-center', scopedSlots: { customRender: 'final' } },
          { title: 'Acciones', key: 'x', class: 'small text-center', scopedSlots: { customRender: 'actions' } },
        ],
      },
      // Table
    }
  },
  methods: {
    async FetchTable(pagE) {
      const params = {
        orderField: 'id',
        orderType: 'desc',
      }
      if (this.containerTypeIdFilter) params.containerTypeId = this.containerTypeIdFilter
      if (this.workCenterIdFilter) params.workCenterId = this.workCenterIdFilter
      if (this.formulationTypeIdFilter) params.formulationTypeId = this.formulationTypeIdFilter
      if (this.startDate) params.initialRecycleDatedAt = this.startDate
      if (this.finalDate) params.finalRecycleDatedAt = this.finalDate
      if (this.manifestFilterText) params.search = this.manifestFilterText
      await this.GetTableResourcev2(pagE, 'finishedProductManifests', this.tableObj, params)
    },
  },
  async mounted() {
    await this.FetchTable()
  },
}
</script>
