<template>
  <div>
    <!-- Filtros -->
    <a-row align="middle">
      <a-col :sm="{ span: 11 }" :md="{ span: 7 }">
        <!-- SELECT Planta -->
        <customDropdownSelect v-model="workCenterIdFilter"
                               label="Planta" resource="workCenters"/>
      </a-col>
      <a-col :sm="{ span: 11 }" :md="{ span: 8 }">
        <!-- SELECT Corriente -->
        <customDropdownSelect v-model="currentIdFilter"
                               label="Corriente" resource="currents"/>
      </a-col>
      <a-col :sm="{ span: 11 }" :md="{ span: 7 }">
        <!-- SELECT Area -->
        <customDropdownSelect v-model="finishedProductAreaIdFilter"
                               label="Área" resource="finishedProductAreas"/>
      </a-col>
      <a-col :sm="{ span: 2 }" :md="{ span: 2 }" :lg="{ span: 2 }" class="mt-5">
        <b-button size="sm" variant="default" @click="FetchTable()">
           <b-icon icon="search"/>
        </b-button>
      </a-col>
      <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
        <!--DATEPICKER - Fecha final-->
        <a-form-model-item  label="Fecha final">
          <a-date-picker  v-model="startDate" placeholder="Fecha final" style="width: 100%"
                          format="DD/MM/YYYY" size="large" valueFormat="YYYY-DD-MM"/>
        </a-form-model-item>
        <!--DATEPICKER -Fecha final -->
      </a-col>
      <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
        <!--DATEPICKER - Fecha final-->
        <a-form-model-item  label="Fecha final">
          <a-date-picker  v-model="finalDate" placeholder="Fecha final" style="width: 100%"
                          format="DD/MM/YYYY" size="large" valueFormat="YYYY-DD-MM"/>
        </a-form-model-item>
        <!--DATEPICKER -Fecha final -->
      </a-col>
    </a-row>
    <!-- Filtros -->
    <!-- Cart -->
    <a-row type="flex" justify="end" v-show="selectedMixtures.length">
      <a-col :span="3">
        <b-button  variant="success" pill @click="showDrawer()"  v-b-tooltip.hover :title="selectedMixtures.length">
          <div class="d-none d-lg-block" v-b-tooltip.hover>{{selectedMixtures.length}}<b-icon icon="cart3"/></div>
          <div class="d-lg-none" v-b-tooltip.hover>{{selectedMixtures.length}}<b-icon icon="cart3"/></div>
        </b-button>
      </a-col>
    </a-row>
    <!-- Cart -->
    <!-- Tabla -->
    <a-row>
      <a-col :sm="tableSizeCol.sm" :md="tableSizeCol.md">
        <h5>Mezclas</h5>
        <hr class="bg-primary">
        <a-table bordered :scroll="{y:1000}"
            :columns="tableObj.columns" :data-source="tableObj.data"
            :pagination="tableObj.pagination" :loading="tableObj.loading"
            :row-key="record => record.id"
            :row-selection="{ width: 100, fixed:true, type: 'radio', selectedRowKeys: selectedRowKeys, selectedRows: selectedRow, onChange: onSelectChange }"
            @change="FetchTable">
            <mixtureTableInfo slot="mixtureTableInfo" slot-scope="row" :mixture="row"/>
            <template slot="actions" slot-scope="row">
              <b-button v-if="!checForMixtureId(row)"
                        @click="Add2Cart(row)"
                        pill variant="primary">
                <b-icon icon="plus"/>
              </b-button>
            </template>
        </a-table>
      </a-col>
      <a-col :sm="{ span: 24 }" :md="{ span: 10, offset:1 }" v-if="selectedRow.length > 0">
        <h5>Listado de Residuos de la Mezcla</h5>
        <hr class="bg-primary">
        <a-table v-if="selectedRow.length > 0 && Object.keys(selectedRow[0]).length > 0"
            bordered :columns="mixturesTitemsColumns"
            :data-source="selectedRow[0].mixture_items" :row-key="record => record.id">
            <template slot="details" slot-scope="row">
              <a-descriptions :title="`${row.t_items.consecutive} - ${row.t_items.code}`" bordered size="small" :column="{xs: 1, sm: 1, lg:2} ">
                <a-descriptions-item label="Transacción">
                  <strong>{{row.t_items.t_details[0].transactions.code}}</strong>
                </a-descriptions-item>
                <a-descriptions-item label="Residuo">
                  <strong>{{row.t_items.t_details[0].sap_material_contracts.materials.name}}</strong>
                </a-descriptions-item>
                <a-descriptions-item label="Cantidad">
                  <strong>{{row.t_items.quantity}}</strong>
                </a-descriptions-item>
                <a-descriptions-item label="Contenedor">
                  <strong>{{row.t_items.t_details[0].container_types.name}}</strong>
                </a-descriptions-item>
                <a-descriptions-item label="Almacén">
                  <strong>{{row.t_items.t_details[0].transactions.warehouses.name}}</strong>
                </a-descriptions-item>
              </a-descriptions>
            </template>
        </a-table>
      </a-col>
    </a-row>
    <!-- Tabla -->
    <!-- Modal -->
    <b-modal title="Manifiesto de Salida de Materiales"  ref="modalForm" size="xl" @hide="CloseManifestModal">
        <a-form-model ref="formRes" layout="horizontal" :model="formObj.form" :rules="formRules">
          <!--Switch - -->
          <a-row type="flex" justify="center">
            <a-form-model-item label="¿Aplica?">
               <a-switch v-model="switch_status_applied"
                          checked-children="Aplica" un-checked-children="No Aplica"/>
            </a-form-model-item>
          </a-row>
          <h6>Datos del Residuo</h6>
          <hr class="bg-primary">
          <!--TEXT - Punto de Alimentación-->
          <a-row align="middle" v-if="!switch_status_applied">
            <a-form-model-item label="Punto de Alimentación" ref="feeding_point"  prop="feeding_point">
               <a-input v-model="formObj.form.feeding_point" placeholder="Punto de Alimentación" size="large"/>
            </a-form-model-item>
          </a-row>
          <a-row align="middle">
            <!--TEXT - No.Lote-->
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <a-form-model-item label="No.Lote" ref="manifest_batch_code"  prop="manifest_batch_code">
                 <a-input v-model="formObj.form.manifest_batch_code" placeholder="No.Lote" size="large"/>
              </a-form-model-item>
            </a-col>
            <!--TEXT - No.Manifiesto de Salida Disposición Final-->
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <a-form-model-item label="No.Manifiesto de Salida Disposición Final" ref="exit_manifest_code"  prop="exit_manifest_code">
                 <a-input v-model="formObj.form.exit_manifest_code" placeholder="No.Manifiesto de Salida Disposición Final" size="large"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row align="middle">
            <!-- SELECT Tipo de Formulación -->
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <customDropdownSelect label="Tipo de Formulación" v-model="formObj.form.formulation_type_id"
                                    refAndPop="formulation_type_id" resource="formulationTypes"/>
            </a-col>
            <!-- SELECT Planta -->
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <customDropdownSelect label="Planta" v-model="formObj.form.work_center_id"
                                    refAndPop="work_center_id" resource="workCenters"/>
            </a-col>
          </a-row>
          <a-row align="middle">
            <!--DATEPICKER - Fecha de reciclaje-->
            <a-col :sm="{ span: 24 }" :md="{ span: 8 }">
              <a-form-model-item  label="Fecha de reciclaje" ref="recycle_dated_at" prop="recycle_dated_at" >
                <a-date-picker  v-model="formObj.form.recycle_dated_at"
                                placeholder="Fecha de reciclaje" style="width:100%;"
                                format="DD/MM/YYYY" size="large" valueFormat="YYYY-MM-DD"/>
              </a-form-model-item>
            </a-col>
            <!--NUMBER INPUT - Cantidad Total-->
            <a-col :sm="{ span: 12 }" :md="{ span: 8 }">
              <a-form-model-item label="Cantidad Total" ref="quantity"  prop="quantity">
                 <a-input-number v-model="formObj.form.quantity" :min="0" :step="1"
                                style="width:100%;" size="large"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 12 }" :md="{ span: 8 }">
              <!-- SELECT Unidad de Medida -->
              <customDropdownSelect label="Unidad de Medida" v-model="formObj.form.u_m_s_id"
                                    refAndPop="u_m_s_id" resource="ums"/>
              <!-- SELECT Unidad de Medida -->
            </a-col>
          </a-row>
          <h6 v-if="switch_status_applied">Datos de Transporte</h6>
          <hr class="bg-primary" v-if="switch_status_applied">
          <a-row align="middle" v-if="switch_status_applied">
            <!--TEXT - Empresa Transportista-->
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <a-form-model-item label="Empresa Transportista" ref="carrier_company_name"  prop="carrier_company_name">
                 <a-input v-model="formObj.form.carrier_company_name" placeholder="Empresa Transportista" size="large"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <!--TEXT - Nombre del Chofer-->
              <a-form-model-item label="Nombre del Chofer" ref="carrier_company_driver"  prop="carrier_company_driver">
                 <a-input v-model="formObj.form.carrier_company_driver" placeholder="Nombre del Chofer" size="large"/>
              </a-form-model-item>
              <!--TEXT -Nombre del Chofer -->
            </a-col>
          </a-row>
          <a-row align="middle" v-if="switch_status_applied">
            <!--TEXT - Tipo de Vehículo-->
            <a-col :sm="{ span: 24 }" :md="{ span: 8 }">
              <a-form-model-item label="Tipo de Vehículo" ref="vehicle_type"  prop="vehicle_type">
                 <a-input v-model="formObj.form.vehicle_type" placeholder="Tipo de Vehículo" size="large"/>
              </a-form-model-item>
            </a-col>
            <!--TEXT - Placas de Tractocamion-->
            <a-col :sm="{ span: 24 }" :md="{ span: 8 }">
              <a-form-model-item label="Placas de Tractocamion" ref="vehicle_tag"  prop="vehicle_tag">
                 <a-input v-model="formObj.form.vehicle_tag" placeholder="Placas de Tractocamion" size="large"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span: 8 }">
              <!--TEXT - Placas de la Caja-->
              <a-form-model-item label="Placas de la Caja" ref="vehicle_tag_coupled"  prop="vehicle_tag_coupled">
                 <a-input v-model="formObj.form.vehicle_tag_coupled" placeholder="Placas de la Caja" size="large"/>
              </a-form-model-item>
              <!--TEXT -Placas de la Caja -->
            </a-col>
          </a-row>
          <a-row align="middle" v-if="switch_status_applied">
            <!--TEXT - No.Autorización SEMARNAT 2-->
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <a-form-model-item label="No.Autorización SEMARNAT 2" ref="semarnat_2"  prop="semarnat_2">
                 <a-input v-model="formObj.form.semarnat_2" placeholder="No.Autorización SEMARNAT 2" size="large"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <!--TEXT - No.Secretaría Relaciones y Transportes-->
              <a-form-model-item label="No.Secretaría Relaciones y Transportes" ref="external_relations_secretary"  prop="external_relations_secretary">
                 <a-input v-model="formObj.form.external_relations_secretary" placeholder="No.Secretaría Relaciones y Transportes" size="large"/>
              </a-form-model-item>
              <!--TEXT -No.Secretaría Relaciones y Transportes -->
            </a-col>
          </a-row>
          <h6>Destinatario Final</h6>
          <hr class="bg-primary">
          <a-row align="middle">
            <!--SELECT -Tipo de Salida -->
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <a-form-model-item label="Tipo de Salida" ref="exit_type" prop="exit_type">
                <a-select v-model="formObj.form.exit_type" placeholder="Tipo de Salida">
                  <a-select-option  v-for="option in exitTypesCombo" :key="option.value" :value="option.value" >
                    {{option.text}}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <!--TEXT - No.Programación-->
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }" v-if="switch_status_applied">
              <a-form-model-item label="No.Programación" ref="programming_code"  prop="programming_code">
                 <a-input v-model="formObj.form.programming_code" placeholder="No.Programación" size="large"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row align="middle">
            <!--TEXT - Razón Social de la empresa de destino final-->
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }" v-if="switch_status_applied">
              <a-form-model-item label="Razón Social de la empresa de destino final" ref="business_name"  prop="business_name">
                 <a-input v-model="formObj.form.business_name" placeholder="Razón Social de la empresa de destino final" size="large"/>
              </a-form-model-item>
            </a-col>
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <!--TEXT - No.Autorización Destino Final-->
              <a-form-model-item :label="authorization_final_destiny_label" ref="authorization_final_destiny"  prop="authorization_final_destiny">
                 <a-input v-model="formObj.form.authorization_final_destiny" :placeholder="authorization_final_destiny_label" size="large"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row align="middle">
            <!--TEXT - Manejo autorizado que dará la empresa al residuo-->
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }" v-if="switch_status_applied">
              <a-form-model-item label="Manejo autorizado que dará la empresa al residuo" ref="authorization_final_code"  prop="authorization_final_code">
                 <a-input v-model="formObj.form.authorization_final_code" placeholder="Manejo autorizado que dará la empresa al residuo" size="large"/>
              </a-form-model-item>
            </a-col>
            <!--TEXT - Responsable-->
            <a-col :sm="{ span: 24 }" :md="{ span: 12 }">
              <a-form-model-item label="Responsable" ref="responsible"  prop="responsible">
                 <a-input v-model="formObj.form.responsible" placeholder="Responsable" size="large"/>
              </a-form-model-item>
            </a-col>
          </a-row>
          <!-- Botones -->
          <a-row type="flex" justify="end" v-show="!spinner">
            <a-col>
              <b-button pill variant="primary" @click="Post">
              <div class="d-none d-md-block" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/> Guardar
              </div>
              <div class="d-md-none" v-b-tooltip.hover title="Guardar">
                <b-icon icon="archive"/>
              </div>
              </b-button>
            </a-col>
          </a-row>
          <!-- Botones -->
        </a-form-model>
        <template v-slot:modal-footer>
          <simple-modal-footer @ClickEvent="CloseManifestModal"/>
        </template>
      </b-modal>
    <!-- Modal -->
    <!-- Draer de Elementos de Analisis -->
    <a-drawer title="Elementos de Análisis" width="720"
              @close="onClose" placement="right" :visible="visibleDrawer">
      <a-row type="flex" justify="end" class="mb-3">
        <b-button pill variant="primary"
                  v-b-tooltip.hover title="Guardar" @click="OpenManifestModal">
          <div class="d-none d-md-block"><b-icon icon="archive"/> Guardar</div>
          <div class="d-md-none"><b-icon icon="archive"/></div>
        </b-button>
      </a-row>
      <a-table bordered :scroll="{y:1000}"
            :columns="tableObj.columns" :data-source="selectedMixtures"
            :pagination="false"
            :row-key="record => record.id">
            <mixtureTableInfo slot="mixtureTableInfo" slot-scope="row" :mixture="row" :short="true"/>
            <template slot="actions" slot-scope="row">
              <b-button v-if="checForMixtureId(row)"
                        @click="RemoveFromCart(row)"
                        pill variant="danger">
               <b-icon icon="trash"/>
              </b-button>
            </template>
        </a-table>
    </a-drawer>
    <!-- Draer de Elementos de Analisis -->
  </div>
</template>

<script>
import mixtureTableInfo from './mixtureTableInfo.vue'
import { exitTypesCombo } from '@/constant/CombosApiV2'
import { apiV2Mixin } from '@/mixins/ApiV2Mixin'
const formDefault = {
  work_center_id: null,
  manifest_batch_code: '',
  exit_manifest_code: '',
  formulation_type_id: null,
  recycle_dated_at: '',
  quantity: 1,
  u_m_s_id: null,
  carrier_company_name: '',
  carrier_company_driver: '',
  vehicle_type: '',
  vehicle_tag: '',
  vehicle_tag_coupled: '',
  semarnat_2: '',
  external_relations_secretary: '',
  exit_type: '',
  programming_code: '',
  business_name: '',
  authorization_final_destiny: '',
  authorization_final_code: '',
  responsible: '',
  is_blocked: true,
  status_applied: 'Aplica',
  feeding_point: '',
}
export default {
  name: 'finishedWarehouseTab',
  mixins: [apiV2Mixin],
  components: {
    mixtureTableInfo,
  },
  data() {
    return {
      // conts
      exitTypesCombo,
      // dom
      visibleDrawer: false,
      spinner: false,
      // Filtros
      workCenterIdFilter: undefined,
      currentIdFilter: undefined,
      finishedProductAreaIdFilter: undefined,
      startDate: '',
      finalDate: '',
      // Filtros
      // Table
      selectedRowKeys: [],
      selectedRow: [],
      tableObj: {
        data: [],
        pagination: {
          current: 1,
          pageSize: 15,
          from: 0,
          to: 0,
          total: 0,
          lastPage: 0,
        },
        loading: false,
        columns: [
          { title: 'Detalles', width: 'auto', class: 'text-center', scopedSlots: { customRender: 'mixtureTableInfo' } },
          { title: 'Acción', key: 'x', width: '10%', class: 'text-center', scopedSlots: { customRender: 'actions' } },
        ],
      },
      // Table
      mixturesTitemsColumns: [
        {
          title: 'Información General',
          key: 'detail',
          class: 'small text-center',
          scopedSlots: {
            customRender: 'details',
          },
        },
      ],
      // MAnifest form
      defaultForm: JSON.stringify(formDefault),
      switch_status_applied: true,
      formObj: {
        resourceType: 'finishedProductManifests',
        form: {},
      },
      selectedMixtures: [],
    }
  },
  computed: {
    tableSizeCol () {
      if (this.selectedRow.length > 0) {
        return { sm: { span: 24 }, md: { span: 13 } }
      } else return { sm: { span: 24 }, md: { span: 24 } }
    },
    formRules () {
      if (this.switch_status_applied) {
        return {
          work_center_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          manifest_batch_code: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          exit_manifest_code: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          formulation_type_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          recycle_dated_at: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          quantity: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          u_m_s_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          carrier_company_name: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          carrier_company_driver: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          vehicle_type: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          vehicle_tag: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          vehicle_tag_coupled: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          semarnat_2: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          external_relations_secretary: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          exit_type: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          programming_code: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          business_name: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          authorization_final_destiny: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          authorization_final_code: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        }
      } else {
        return {
          manifest_batch_code: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          exit_manifest_code: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          work_center_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          recycle_dated_at: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          quantity: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          u_m_s_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          exit_type: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          authorization_final_destiny: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          feeding_point: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          responsible: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
          formulation_type_id: [{ required: true, message: 'Campo obligatorio.', trigger: 'change' }],
        }
      }
    },
    authorization_final_destiny_label() {
      return this.switch_status_applied ? 'No.Autorización Destino Final' : 'Planta Destino'
    },
  },
  methods: {
    // helpers
    checForMixtureId(mixture) {
      return this.selectedMixtures.some(mix => (mix.id === mixture.id)) || false
    },
    // table
    async FetchTable(pagE) {
      this.selectedMixtures = []
      const params = {
        watseStatus: 'Concluido',
        finishedProductStatus: 'Pendiente',
      }
      if (this.workCenterIdFilter) params.workCenterId = this.workCenterIdFilter
      if (this.currentIdFilter) params.currentId = this.currentIdFilter
      if (this.finishedProductAreaIdFilter) params.finishedProductAreaId = this.finishedProductAreaIdFilter
      if (this.startDate) params.initialDatedAt = this.startDate
      if (this.finalDate) params.finalDatedAt = this.finalDate
      await this.GetTableResourcev2(pagE, 'mixtures', this.tableObj, params)
    },
    // Table selection Item
    async onSelectChange(_selectedRowKeys, selectedRows) {
      this.selectedRow = selectedRows
      this.selectedRowKeys = _selectedRowKeys
    },
    // drawer de elementos
    showDrawer() {
      this.visibleDrawer = true
    },
    onClose() {
      this.visibleDrawer = false
    },
    // Modal de manifiesto
    Add2Cart(mixture) {
      this.selectedMixtures.push(mixture)
    },
    RemoveFromCart(mixture) {
      const i = this.selectedMixtures.map(item => item.id).indexOf(mixture.id)
      this.selectedMixtures.splice(i, 1)
    },
    OpenManifestModal() {
      this.spinner = false
      this.formObj.form = JSON.parse(this.defaultForm)
      this.$refs.modalForm.show()
      this.switch_status_applied = true
    },
    CloseManifestModal() {
      this.spinner = false
      this.formObj.form = JSON.parse(this.defaultForm)
      this.$refs.modalForm.hide()
      this.switch_status_applied = true
    },
    async Post() {
      this.$refs.formRes.validate(async(valid) => {
        if (valid) {
          this.spinner = true
          this.formObj.form.status_applied = this.switch_status_applied ? 'Aplica' : 'No Aplica'
          this.formObj.form.mixtures = this.selectedMixtures.map(titem => titem.id)
          await this.PostResourcev2(this.formObj, async () => {
            this.spinner = false
            this.CloseManifestModal()
            await this.FetchTable()
          }, () => { this.spinner = false })
        } else return false
      })
    },
  },
  async mounted() {
    await this.FetchTable()
  },
}
</script>
